import MarkdownIt from 'markdown-it';

const defaultRender = (tokens, idx, options, env, self) =>
  self.renderToken(tokens, idx, options);

/**
 * Markdown parser set to open links in a new tab *unless* it's a `tel:` link
 * @returns {markdownParser} 
 */
export const buildMarkdownParser = () => {
  const md = new MarkdownIt();

  const render = md.renderer.rules.link_open || defaultRender;

  md.renderer.rules.link_open = (tokens, idx, options, env, self) => {
    const aIndex = tokens[idx].attrIndex('target');
    const href = tokens[idx].attrGet('href') || '';

    const isPhoneLink = href.indexOf('tel:') != -1;

    if (!isPhoneLink) {
      if (aIndex < 0) {
        tokens[idx].attrPush(['target', '_blank']);
      } else {
        tokens[idx].attrs[aIndex][1] = '_blank';
      }
    }

    return render(tokens, idx, options, env, self);
  };

  return md;
};
