import React, { useState } from 'react';
import { FormattedMessage } from '@kyruus/intl';
import _clone from 'lodash/clone';
import _kebabCase from 'lodash/kebabCase';
import _map from 'lodash/map';
import _remove from 'lodash/remove';
import _slice from 'lodash/slice';

import { V9_DIRECT_BOOK_CAPABLE_FILTER } from '../../../../utils/constants';
import { FacetWrapper, BasicFacetBody } from '../../../styles';
import { ClearFilter } from '../clear-filter';
import { ShowMoreButton } from './show-more-button';

function BookOnlineIcon() {
  return (
    <span
      className="icon-event-available fc-positive fs-l va-b mr-xs"
      id="book-online-facet-icon"
    />
  );
}

function isBookOnlineFacet(config) {
  return config.filter_param === V9_DIRECT_BOOK_CAPABLE_FILTER;
}

function Filter({ config, type, facetName, hideCount, toggleFilter }) {
  const id = 'facet-' + _kebabCase(config.value) + '-' + _kebabCase(facetName);
  const dataTestId = `facet-${_kebabCase(config.value)}-${_kebabCase(
    facetName
  )}`;
  const is_book_online = isBookOnlineFacet(config);
  const filterLabelMessageDescriptor = {
    id: `field.value.${facetName}.${config.value}`,
    defaultMessage: config.value,
    description: 'The label for the filter'
  };
  return (
    <li>
      <label htmlFor={id} className="pr-s" id={id + '-label'}>
        <input
          id={id}
          data-testid={dataTestId}
          type={type}
          onClick={() => toggleFilter(config.filter_param)}
          defaultChecked={config.applied}
          name={facetName}
          className="mr-xs"
        />
        {is_book_online ? <BookOnlineIcon /> : null}
        <FormattedMessage {...filterLabelMessageDescriptor} />
        {!hideCount && config.count ? (
          <span className="count"> ({config.count})</span>
        ) : null}
      </label>
    </li>
  );
}

export const BasicFacet = ({
  config,
  facetName,
  log,
  labelName,
  searchableLocation,
  getUpdatedSearch,
  hideCount,
  updateSearch
}) => {
  const [hideTerms, setHideTerms] = useState(true);
  const maxVisible = config.max_visible || 5;
  const terms = _clone(config.terms);
  const appliedTerms = _remove(terms, 'applied');

  const toggleShowMore = () => {
    setHideTerms(!hideTerms);
  };

  const handleAddFilter = (filterParam) => {
    setHideTerms(true);
    const modifications = [
      { action: 'append', key: 'filter', value: filterParam }
    ];
    // remove all other applied filters for the facetName if type is radio button
    if (config.type === 'radio' && appliedTerms.length > 0) {
      const deleteFilters = appliedTerms.map(({ value }) => ({
        action: 'delete_key_value',
        key: 'filter',
        value: `${facetName}:${value}`
      }));
      modifications.push(...deleteFilters);
    }
    updateSearch(modifications);
  };
  const handleRemoveFilter = (filterParam) => {
    setHideTerms(true);
    updateSearch([
      { action: 'delete_key_value', key: 'filter', value: filterParam }
    ]);
  };

  const appliedFilters = _map(appliedTerms, (filter) => (
    <Filter
      type={config.type}
      config={filter}
      facetName={facetName}
      hideCount={hideCount}
      toggleFilter={handleRemoveFilter}
      key={filter.value}
    />
  ));

  const to_show = hideTerms
    ? Math.max(maxVisible - appliedTerms.length, 0)
    : terms.length;
  const unappliedTerms = _slice(terms, 0, to_show);
  const unappliedFilters = _map(unappliedTerms, (filter) => (
    <Filter
      type={config.type}
      config={filter}
      facetName={facetName}
      hideCount={hideCount}
      toggleFilter={handleAddFilter}
      key={filter.value}
    />
  ));

  return (
    <FacetWrapper className="filter-box multi">
      <BasicFacetBody
        data-testid={`filter-box-${_kebabCase(facetName)}`}
        className="no-bullets"
        id={'facet-group-' + _kebabCase(facetName)}
      >
        {appliedFilters}
        {unappliedFilters}
      </BasicFacetBody>
      <ShowMoreButton
        allTerms={config.terms}
        facetName={facetName}
        hideTerms={hideTerms}
        labelName={labelName}
        maxVisible={maxVisible}
        onClick={toggleShowMore}
        terms={terms}
      />
      <ClearFilter
        config={config}
        facetName={facetName}
        getUpdatedSearch={getUpdatedSearch}
        labelName={labelName}
        log={log}
        onClick={() => {
          setHideTerms(true);
        }}
        searchableLocation={searchableLocation}
      />
    </FacetWrapper>
  );
};

BasicFacet.displayName = 'BasicFacet';
