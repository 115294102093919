import querystring from 'querystring';
import _omit from 'lodash/omit';

import { CTA_SHOW_MAP_VIEW } from './constants';

export const noop = () => {};

/**Returns primary marketable location (mloc) ids. */
export const getLocationIdsFromProvidersData = (providersData = {}) => {
  const mlocFacet = (providersData.facets || []).find(
    ({ field }) => field === 'locations.primary_marketable_location_id'
  );

  return mlocFacet?.terms.map(({ value }) => value) ?? [];
};

/**
 * Callback function when MapSearchLinkBlock is clicked
 * @param {object} e the event fired
 * @param {function} log analytics logging
 * @param {string} mapSearchUrl the URL to the Map Search page
 * @param {object} history react-router history
 * @returns undefined
 */
export const mapSearchCallbackFunction = (e, log, mapSearchUrl, history) => {
  e.preventDefault();
  log(CTA_SHOW_MAP_VIEW);
  history.push(mapSearchUrl);
};

export const getBackToListView = (locationsQuery, omitParams) => {
  const queryParams = querystring.parse((locationsQuery || '').substring(1));
  const newQueryParams = _omit(queryParams, omitParams);
  return `/search?${querystring.stringify(newQueryParams)}`;
};

/**
 * Formats a map location to be passed to the @kyruus/gmap component
 * @param {object} mloc
 * @returns {object}
 */
export const formatLocationForGoogleMap = (mloc) => ({
  name: `View providers at ${mloc.name}`, // hacky way of ensuring @kyruus/gmap renders this as aria-label
  id: mloc.id,
  coordinates: (mloc.address || {}).coordinates
});

/**
 * Generates a document querySelector for a location's Google Maps pin
 * @param {object} selectedLocation { name: string }
 * @returns string
 */
export const generateLocationPinQuerySelector = (selectedLocation) => {
  const formatted = formatLocationForGoogleMap(selectedLocation);

  return `div[role="button"][aria-label="${formatted.name}"][title="${formatted.name}"]`;
};

/**
 * Gets a gmap LatLng objects from an mloc.
 * https://developers.google.com/maps/documentation/javascript/reference/coordinates#LatLngLiteral
 * @param {} mloc
 * @returns
 */
export function gMapLatLngForMloc(mloc) {
  return {
    lat: mloc.address.coordinates.lat,
    lng: mloc.address.coordinates.lon
  };
}
