import React from 'react';
import { injectIntl, FormattedMessage } from '@kyruus/intl';

import {
  MobileClickableBackground,
  MobileFakeButton,
  DesktopClickableBackground,
  DesktopFakeButton
} from './styles';

import { messages } from '../utils';

const MapSearchLinkBlockComponent = ({
  backgroundImgUrl,
  linkUrl,
  forMobile = true,
  onClick,
  children: svg,
  className,
  intl
}) => {
  return forMobile ? (
    <MobileClickableBackground
      data-testid="MapSearchLink-Mobile"
      backgroundImgUrl={backgroundImgUrl}
      href={linkUrl}
      onClick={onClick}
      aria-label={intl.formatMessage(messages.mapSearchLinkAriaLabel)}
      className={className}
    >
      <MobileFakeButton>
        <FormattedMessage {...messages.mapSearchLinkText} />
        {svg}
      </MobileFakeButton>
    </MobileClickableBackground>
  ) : (
    <DesktopClickableBackground
      data-testid="MapSearchLink-Desktop"
      backgroundImgUrl={backgroundImgUrl}
      href={linkUrl}
      onClick={onClick}
      aria-label={intl.formatMessage(messages.mapSearchLinkAriaLabel)}
      className={className}
    >
      <DesktopFakeButton>
        <FormattedMessage {...messages.mapSearchLinkText} />
        {svg}
      </DesktopFakeButton>
    </DesktopClickableBackground>
  );
};

const MapSearchLinkBlock = injectIntl(MapSearchLinkBlockComponent);
export default MapSearchLinkBlock;
