import React from 'react';
import Map from '@kyruus/react-ikons/Map';
import { defineMessages, FormattedMessage } from '@kyruus/intl';
import SortMenu from '../../shared/sort-menu';
import { FacetList } from './facet-list';
import { MobileFiltersHeader } from '../results-header';
import MapSearchLinkBlock from '../map-search-link-block';
import { MAP_BG_URL } from '../map-search-link-block/constants';
import { mapSearchModuleEnabled } from '../../utils/map-search';
import { mapSearchCallbackFunction } from '../../map-search/lib/utils';
import {
  StyledFacetPanel,
  StyledFacetListHeader,
  StyledFacetListHeaderWithMobileFacets
} from '../styles';

const messages = defineMessages({
  facetlistheader: {
    id: 'facetlist.header',
    description: 'Header for a list of search results filters to apply',
    defaultMessage: 'Filters'
  },
  sortby: {
    id: 'resultsheader.sortby',
    description: 'Header for a sort dropdown',
    defaultMessage: 'Sort by'
  }
});

export const FacetPanel = ({
  showMobileFacets,
  searchSummary,
  sortOptions,
  totalProviders,
  log,
  getUpdatedSearch,
  toggleMobileFacets,
  facets,
  distanceSort,
  showLocationFacet,
  config,
  history,
  mapSearchUrl,
  isScreenMediumOrBelow,
  showFiltersInDrawer // temporary prop to show filter in drawer
}) => {
  // iOS Safari: This prevents the page behind the modal from scrolling when the modal "bounces" at the end or beginning KENG-3993
  // This solution is based on this article: https://medium.com/turo-engineering/ios-mobile-scroll-in-web-react-1d92d910604b
  const preventScrollBounce = (event) => {
    const { scrollTop, offsetHeight, scrollHeight } = event.currentTarget;
    if (scrollTop <= 0) {
      event.currentTarget.scrollTo(0, 1);
      return;
    }

    if (scrollTop + offsetHeight >= scrollHeight) {
      event.currentTarget.scrollTo(0, scrollHeight - offsetHeight - 1);
    }
  };

  const { updated_filter_bar: showMapLinkInDrawer } =
    config.darkship_feature_flags;

  const primaryMlocFacet =
    facets &&
    facets.find((f) => f.field === 'locations.primary_marketable_location_id');

  // This check decide to show/hide maps link on search list page, based on:-
  // large screen (showMobileFacets), maps enable in CS (mapSearchModuleEnabled), MLOC facet data (primaryMlocFacet)
  const shouldShowMapLink =
    totalProviders > 0 &&
    !showMobileFacets &&
    mapSearchModuleEnabled(config) &&
    primaryMlocFacet &&
    primaryMlocFacet.total > 0 &&
    !showMapLinkInDrawer;

  /* 
  TODO https://healthsparq.atlassian.net/browse/HSAP-1006
  Remove any code that renders based on showMobileFacets once migration is done
  showMobileFacets is always undefined when the updated filter bar darkship flag is on
  isUpdatedMobileView can just become mobileView after that
  */
  const isUpdatedMobileView = isScreenMediumOrBelow && showFiltersInDrawer;

  return (
    <StyledFacetPanel
      className={`facet-panel ${
        showMobileFacets || showFiltersInDrawer ? '' : 'hidden-xs hidden-sm' // showFiltersInDrawer is a temporary check to show filter in drawer
      }`}
      showMobileFacets={showMobileFacets}
      onTouchStart={showMobileFacets ? preventScrollBounce : undefined}
    >
      {showMobileFacets && (
        <React.Fragment>
          <MobileFiltersHeader
            searchSummary={searchSummary}
            log={log}
            getUpdatedSearch={getUpdatedSearch}
            toggleMobileFacets={toggleMobileFacets}
          />

          {sortOptions && (
            <div
              className="separator col-md-3 pb-s hidden-print sortMenuList mb-m"
              data-view="sort"
            >
              <h1 className="mb-m fs-l">
                <FormattedMessage {...messages.sortby} />
              </h1>
              <SortMenu
                searchSummary={searchSummary}
                sortOptions={sortOptions}
                getUpdatedSearch={getUpdatedSearch}
                idPrefix="mobile-"
              />
            </div>
          )}
        </React.Fragment>
      )}
      {isUpdatedMobileView && (
        <div
          className="separator col-md-3 pb-s hidden-print sortMenuList mb-m"
          data-view="sort"
        >
          <h1 className="mb-m fs-l">
            <FormattedMessage {...messages.sortby} />
          </h1>
          <SortMenu
            searchSummary={searchSummary}
            sortOptions={sortOptions}
            getUpdatedSearch={getUpdatedSearch}
            idPrefix="mobile-"
          />
        </div>
      )}
      <div className="filter-search-mobile">
        {
          // desktop link block (mobile is in providermatch_consumer/static/src/search-v9/index.jsx)
          shouldShowMapLink && (
            <MapSearchLinkBlock
              backgroundImgUrl={MAP_BG_URL}
              linkUrl={mapSearchUrl}
              forMobile={false}
              onClick={(e) => {
                mapSearchCallbackFunction(e, log, mapSearchUrl, history);
              }}
            >
              <Map size="42px" />
            </MapSearchLinkBlock>
          )
        }
        {showMobileFacets ? (
          <StyledFacetListHeaderWithMobileFacets
            showMobileFacets={showMobileFacets}
          >
            <FormattedMessage {...messages.facetlistheader} />
          </StyledFacetListHeaderWithMobileFacets>
        ) : (
          <StyledFacetListHeader showMobileFacets={showMobileFacets}>
            <FormattedMessage {...messages.facetlistheader} />
          </StyledFacetListHeader>
        )}
        <FacetList
          searchSummary={searchSummary}
          config={config}
          facets={facets}
          getUpdatedSearch={getUpdatedSearch}
          distanceSort={distanceSort}
          log={log}
          showLocationFacet={showLocationFacet}
        />
      </div>
    </StyledFacetPanel>
  );
};

FacetPanel.displayName = 'FacetPanel';
