import React from 'react';
import _ from 'lodash';
import { defineMessages } from '@kyruus/intl';

import { ClearFilter } from '../clear-filter';
import { FacetWrapper, TypeaheadSelectBody } from '../../../styles';
import { IntlReactSelect } from '../../../../utils/intl-components';

const messages = defineMessages({
  topfilter: {
    id: 'facet.labels.topfilter',
    description: 'Label for the most popular filter',
    defaultMessage: 'Popular choices'
  },
  allfilter: {
    id: 'facet.labels.allfilter',
    description: 'Label for all other filter options',
    defaultMessage: 'All choices'
  },
  defaultplaceholder: {
    id: 'facet.typeahead.placeholder.default',
    description: 'Default placeholder text for a typeahead facet',
    defaultMessage: 'Type here...'
  },
  arialabel: {
    id: 'aria.label.facet.typeahead',
    description: 'Accessibility label for a typeahead facet',
    defaultMessage: 'Type here for filter options'
  }
});

export const TypeaheadFacet = ({
  config,
  facetName,
  hideCount,
  addFilter,
  removeFilter,
  searchableLocation,
  log,
  getUpdatedSearch,
  labelName
}) => {
  // We want the default text here to be sent to translators, but since the ID can't
  // be determined statically, we can't send it through the defineMessages API.
  const placeholderMessageDescriptor = {
    id: `facet.${config.field}.typeahead.placeholder`,
    defaultMessage: ''
  };

  let filters = _.map(config.terms, function (filter) {
    const labelMessageDescriptor = {
      id: `field.value.${facetName}.${filter.value}`,
      defaultMessage: filter.value,
      description: 'The label for the filter'
    };
    return {
      labelMessageDescriptor: labelMessageDescriptor,
      value: filter.filter_param,
      applied: filter.applied,
      top_filter: filter.top_filter,
      count: filter.count
    };
  });
  const applied = _.chain(filters).filter('applied').map('value').value();
  const top_filters = _.chain(filters)
    .remove('top_filter')
    .map((filter) =>
      _.pick(filter, ['labelMessageDescriptor', 'value', 'count'])
    )
    .value();
  filters = _.map(filters, (filter) =>
    _.pick(filter, ['labelMessageDescriptor', 'value', 'count'])
  );

  let optionsMessageDescriptors;
  if (top_filters.length) {
    optionsMessageDescriptors = [
      {
        labelMessageDescriptor: messages.topfilter,
        options: top_filters
      },
      {
        labelMessageDescriptor: messages.allfilter,
        options: filters
      }
    ];
  } else {
    optionsMessageDescriptors = [
      {
        options: filters
      }
    ];
  }

  const onChange = (changed) => {
    const changedValues = _.map(changed, 'value');
    if (changedValues.length > applied.length) {
      const toAdd = _.difference(changedValues, applied);
      addFilter(toAdd[0]);
    } else if (changedValues.length < applied.length) {
      const toRemove = _.difference(applied, changedValues);
      removeFilter(toRemove[0]);
    }
  };

  return (
    <FacetWrapper
      id={_.kebabCase(facetName)}
      data-testid={`select-area-${_.kebabCase(facetName)}`}
    >
      <TypeaheadSelectBody>
        <IntlReactSelect
          instanceId={_.kebabCase(facetName)}
          multi={true}
          clearable={false}
          value={applied}
          placeholderMessageDescriptor={placeholderMessageDescriptor}
          defaultPlaceholderMessageDescriptor={messages.defaultplaceholder}
          optionsMessageDescriptors={optionsMessageDescriptors}
          ariaMessageDescriptor={messages.arialabel}
          onChange={onChange}
          optionRenderer={(item) => (
            <>
              {item.label}
              {!hideCount && item.count ? (
                <span className="count"> ({item.count})</span>
              ) : null}
            </>
          )}
        />
      </TypeaheadSelectBody>
      <ClearFilter
        config={config}
        facetName={facetName}
        searchableLocation={searchableLocation}
        log={log}
        getUpdatedSearch={getUpdatedSearch}
        labelName={labelName}
      />
    </FacetWrapper>
  );
};

TypeaheadFacet.displayName = 'TypeaheadFacet';
