import { useEffect } from 'react';

export const useKeydownListener = (key, callback) => {
  useEffect(() => {
    const listener = (e) => {
      if (e.key === key) {
        callback(e);
      }
    };

    window.document.addEventListener('keydown', listener);
    return () => window.document.removeEventListener('keydown', listener);
  }, [key, callback]);
};

export const useAnchorClickListener = (querySelector, callback) => {
  useEffect(() => {
    const node = window.document.querySelector(querySelector);
    const listener = (e) => {
      const origin = e.target.closest(`a`);

      if (origin) {
        callback(origin.href);
      }
    };

    if (node) {
      node.addEventListener('click', listener);
    }

    return () => {
      if (node) {
        node.removeEventListener('click', listener);
      }
    };
  }, [querySelector, callback]);
};
