import styled from '@emotion/styled';
import { fromTheme } from '@kyruus/ui-theme';

export const MobileClickableBackground = styled('a')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
  border-radius: ${fromTheme('border_radius')};
  margin-top: 20px;

  // See KENG-22979: spoke with Design about hardcoding to #2a3d5199 as there may be customers who are using lighter color_text colors
  // RRGGBBAA opacity values. See https://stackoverflow.com/a/8254129 (e.g. 60% => '99')
  background: linear-gradient(#2a3d5199, #2a3d5199),
    url(${(props) => props.backgroundImgUrl});
  background-size: cover;

  &:hover {
    text-decoration: none;
  }

  @media (min-width: 992px) {
    // So we don't show mobile version on larger screens
    display: none;
  }
`;

export const MobileFakeButton = styled('span')`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${fromTheme('color_text')};
  background-color: ${fromTheme('color_background_secondary')};
  border-radius: ${fromTheme('border_radius')};
  font-size: ${fromTheme('font_size_heading_4')};
  padding: 0 20px;

  &:hover {
    text-decoration: none;
  }

  @media (min-width: 992px) {
    // So we don't show mobile version on larger screens
    display: none;
  }
`;

export const DesktopClickableBackground = styled(MobileClickableBackground)`
  height: 80px;
  margin-top: 0px;
  margin-bottom: 20px;

  @media (min-width: 992px) {
    display: flex;
  }
`;

export const DesktopFakeButton = styled(MobileFakeButton)`
  font-size: ${fromTheme('font_size_heading_5')};

  @media (min-width: 992px) {
    display: flex;
  }
`;
